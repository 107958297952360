<template>
  <BaseButton
    keyTextButtonTranslate="continueOrder"
    :handlerClick="handlerContinueOrder"
    :disabled="!ICanContinueWithOrder"
  />
</template>
<script>
import {
  orderTypeName,
  orderTypeStoreActions,
  orderTypeStoreGetters,
} from "@/store/modules/orderType/constNames";
import { userInfoStoreGetters } from "@/store/modules/userInfo/constNames";

export default {
  name: "ContinueOrder",
  inject: {
    handleCloseDialog: {
      from: "handleCloseDialog",
      default: () => {},
    },
  },
  components: {
    BaseButton: () => import("@/components/shared/BaseButtons/Index"),
  },
  computed: {
    ICanContinueWithOrder() {
      const orderType =
        this.$store.getters[orderTypeStoreGetters.GET_ORDER_TYPE];
      const { LOCAL, TO_PICK_UP, AT_HOME } = orderTypeName;
      if (orderType === TO_PICK_UP || orderType === AT_HOME) {
        const userPhone =
          this.$store.getters[userInfoStoreGetters.GET_USER_PHONE];
        if (!userPhone) return false;
      }
      if (orderType === AT_HOME) {
        const orderAddress =
          this.$store.getters[orderTypeStoreGetters.GET_ADDRESS];
        if (!orderAddress) return false;
      }
      if (orderType === LOCAL) {
        const tableId = localStorage.getItem("tableId");
        if (!tableId) return false;
      }
      return true;
    },
  },
  methods: {
    handlerContinueOrder() {
      const updateIsConfigured = () =>
        this.$store.dispatch(orderTypeStoreActions.UPDATE_IS_CONFIGURED, true);
      if (this.ICanContinueWithOrder) {
        updateIsConfigured();
        this.handleCloseDialog && this.handleCloseDialog();
      }
      const { fromRoute } = this.$route.params;
      fromRoute && this.$router.push({ name: fromRoute });
    },
  },
};
</script>
